<template>
  <div class="task-information-block">
    <div class="task-information-block__item">
      <div class="task-information-block__title">{{ subtaskCountry }}</div>
      <div class="task-information-block__text">Всего подзадач по целевым странам</div>
    </div>
    <div class="task-information-block__item">
      <div class="task-information-block__title">{{ subtaskCompleted }}</div>
      <div class="task-information-block__text">Выполнено подзадач</div>
    </div>
    <div v-if="!editCountries" class="task-information-block__item big">
      <IconComponent
        @click="onEditCountries"
        name="edit"
        class="gray-stroke-path task-information-block__edit-countries icon--full-svg"
      />
      <div class="task-information-block__title">{{ targetCountries }}</div>
      <div class="task-information-block__text">Всего целевых стран</div>
      <div :class="['task-information-block__wrap-list', { full: fullList }]" v-if="copyCountries.length">
        <ul :class="['task-information-block__countries', { full: fullList }]" ref="listCountries">
          <li
            v-for="(item, index) in copyCountries"
            :key="index"
            :id="item.id"
            @click="nexPageCrossCuttingTaskCard(item.id)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div @click="showMore" class="task-information-block__show-more" v-if="getVisibleShowButon">
          {{ fullList ? 'скрыть' : 'еще' }}
        </div>
      </div>
    </div>
    <div v-if="editCountries" class="task-information-block__item big edit">
      <IconComponent
        @click="onClose"
        name="edit"
        class="blue-stroke-path task-information-block__edit-countries icon--full-svg"
      />
      <div class="task-information-block__title">{{ targetCountries }}</div>
      <div class="task-information-block__text">Всего целевых стран</div>
      <div class="task-information-block__wrap-list" v-if="copyCountries.length">
        <ul class="task-information-block__countries edit">
          <li
            v-for="(item, index) in copyCountries"
            :key="index"
            :id="item.id"
            @click="onDeleteCountry(item.id)"
            class="red-hover"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div v-if="copyCountries.length" class="task-information-block__help">
        Нажмите, чтобы удалить страну из списка
      </div>
      <div class="task-information-block__select-wrap">
        <div class="select__label">Добавить страны</div>
        <SelectMultiComponent
          id="add-new-country"
          v-model="selectId"
          :options="getListCountry"
          placeholder="Выберите"
          modifier="rectangular"
          @select="onSelect"
        />
      </div>
      <div v-if="errorUpdateCountries" class="error-content">Произошла ошибка, пожалуйста, повторите попытку.</div>
      <div class="task-information-block__button-wrap">
        <ButtonComponent
          @click="onSaveChange"
          :disabled="!copyCountries.length"
          mod="gradient-bg"
          class="button-redesign--map-apply"
          >Сохранить</ButtonComponent
        >
        <ButtonComponent @click="onClose" mod="transparent-bg" class="button-redesign--map-apply"
          >Отменить</ButtonComponent
        >
      </div>
    </div>
  </div>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import resizeEventListener from '@/common/mixins/resizeEventListener';
  import IconComponent from '@/common/ui/IconComponent.vue';

  export default {
    name: 'TaskInformationBlock',
    components: { IconComponent, SelectMultiComponent, ButtonComponent },
    props: {
      subtaskCountry: {
        type: [String, Number],
      },
      subtaskCompleted: {
        type: [String, Number],
      },
      targetCountries: {
        type: [String, Number],
      },
      countries: {
        type: Array,
        default: () => [],
      },
      countriesAll: {
        type: Array,
        default: () => [],
      },
      idTask: {
        type: [String, Number],
      },
      errorUpdateCountries: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [resizeEventListener],
    data() {
      return {
        editCountries: false,
        copyCountries: JSON.parse(JSON.stringify(this.countries)),
        selectId: null,
        widthBlockList: 0,
        widthScrollBlockList: 0,
        windowSize: 0,
        fullList: false,
      };
    },
    watch: {
      countries: {
        handler(val) {
          this.copyCountries = val;
        },
      },

      getListCountry() {
        setTimeout(() => this.updatingSizes());
      },
    },
    computed: {
      getWidth() {
        return this.widthBlockList;
      },
      getWidthScroll() {
        return this.widthScrollBlockList;
      },
      getWindowSize() {
        return this.windowSize;
      },
      getVisibleShowButon() {
        if (this.getWidthScroll > this.getWidth) {
          if (this.getWindowSize <= 1023) {
            return true;
          }
        }

        return false;
      },

      getListCountry() {
        const list = this.countriesAll.filter((i) => {
          const item = this.copyCountries.some((j) => +i.value === +j.id);
          return item ? false : i;
        });
        return list;
      },
    },
    methods: {
      nexPageCrossCuttingTaskCard(id) {
        this.$router.push({ name: 'cross-cutting-task-card', params: { id, task: this.idTask } });
      },

      onEditCountries() {
        this.selectId = '';
        this.editCountries = true;
      },

      onDeleteCountry(id) {
        this.copyCountries = this.copyCountries.filter((i) => i.id !== id);
      },

      onSaveChange() {
        if (this.copyCountries.length) {
          this.$emit('saveChange', this.copyCountries);
        }
      },

      onClose() {
        this.editCountries = false;
        this.copyCountries = JSON.parse(JSON.stringify(this.countries));
      },

      onSelect(id, obj) {
        const index = this.copyCountries.findIndex((i) => i.id === id);
        if (index !== -1) {
          return;
        }
        const { label = '', value = '' } = obj;
        this.copyCountries.push({
          id: value,
          name: label,
        });

        setTimeout(() => (this.selectId = null), 0);
      },

      showMore() {
        this.fullList = !this.fullList;
      },

      updatingSizes() {
        const list = this.$refs.listCountries;
        this.windowSize = window.innerWidth;
        if (list?.clientWidth) {
          this.widthBlockList = list.clientWidth;
        }
        if (list?.scrollWidth) {
          this.widthScrollBlockList = list.scrollWidth;
        }
      },
      // метод вызывается в миксине resizeEventListener
      /**@public */
      onResize() {
        this.updatingSizes();
      },
    },
  };
</script>

<style scoped lang="scss">
  .task-information-block {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: calc(100% - 67% + 8px);
    margin-right: -8px;

    &__edit-countries {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 24px;
      top: 16px;
    }

    &__item {
      padding: 16px 24px;
      border-radius: 12px;
      background: $light-grey-3;
      width: calc(50% - 16px);
      margin: 0 8px 16px;
      position: relative;
      min-height: 127px;

      &.big {
        width: calc(100% - 16px);
      }

      &.edit {
        background: $blue-light;
      }
    }

    &__title {
      color: $blue;
      font: $fira-32-M;
      margin-bottom: 4px;
    }

    &__text {
      font: $fira-14;
    }

    &__countrie {
      display: inline-block;
      font: 14px / 24px 'Fira Sans';
      margin-right: 12px;
      cursor: pointer;
      transition: 0.3s ease;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        color: $blue;
      }
    }

    &__wrap-list {
      margin-top: 16px;
    }

    &__countries {
      padding: 0;
      margin: 0 -5px 0;
      display: flex;
      flex-wrap: wrap;

      li {
        font: 14px / 24px 'Fira Sans';
        margin: 0 5px;
        display: inline-block;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          color: $blue;
        }

        &.red-hover {
          &:hover {
            color: $red;
          }
        }
      }
    }

    &__help {
      font: $fira-12;
      margin-top: 8px;
      color: $dark-grey-1;
    }

    &__select-wrap {
      margin: 16px 0;
    }

    &__button-wrap {
      display: flex;
    }

    &__show-more {
      font: 14px / 24px 'Fira Sans';
      color: $blue;
    }

    @media (max-width: $tablet) {
      width: calc(100% + 16px);
      margin: 0 -8px;

      &__item {
        width: calc(25% - 16px);

        &.big {
          width: calc(50% - 16px);
        }
      }

      &__wrap-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      &__countries {
        &:not(&.edit) {
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          width: calc(100% - 25px);
          margin-right: 0;

          li {
            display: inline;
          }

          &.full {
            display: flex;
            width: auto;
            margin: 0 -5px;
            height: auto;
          }
        }
      }
    }

    @media (max-width: $laptop) {
      &__item {
        width: calc(50% - 16px);
        padding: 8px 16px;

        &.big {
          width: calc(100% - 16px);
        }
      }

      &__title {
        font-size: 24px;
        line-height: 32px;
      }

      &__edit-countries {
        right: 16px;
        top: 8px;
        width: 20px;
        height: 20px;
      }

      &__wrap-list {
        margin-top: 4px;

        .edit & {
          margin-top: 16px;
        }
      }
    }
  }
</style>
