<template>
  <form action="/" class="form-create-request">
    <div class="form-create-request__title">Сквозная задача</div>
    <div class="coll coll-12">
      <ComponentInput label="Наименование Сквозной задачи" placeholder="" v-model="name" mod="rectangular" disabled />
    </div>
    <div class="coll coll-12">
      <div class="label-field">Страна <span>*</span></div>
      <SelectMultiComponent
        v-model="countryId"
        :options="optionCountry"
        placeholder="Выберите из списка"
        :classSelect="{ error: errorCountry }"
        modifier="rectangular"
      />
      <div v-if="errorCountry" class="invalid-msg">Поле обязательно для заполнения</div>
    </div>
    <div class="coll coll-6 coll-m-12">
      <div class="label-field">Ответственный ФОИВ <span>*</span></div>
      <SelectMultiComponentCheckbox
        v-model="responsibleFOIV"
        placeholder="Выберите из списка"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="optionResponsibleFOIV"
        :error="errorResponsibleFOIV"
      />
      <div v-if="errorResponsibleFOIV" class="invalid-msg">Поле обязательно для заполнения</div>
    </div>
    <div class="coll coll-6 coll-m-12">
      <div class="label-field">Сотрудник Минэкономразвития России</div>
      <SelectMultiComponentCheckbox
        v-model="employees"
        placeholder="Выберите из списка"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="optionEmployees"
      />
    </div>
    <div class="coll coll-12">
      <ComponentInput
        label="Наименование запроса <span>*</span>"
        placeholder="Напишите текст вашего запроса"
        v-model="textRequest"
        mod="rectangular no-resize"
        type="textarea"
        required
      />
    </div>
    <div class="coll coll-6 coll-t-12 coll-m-12">
      <ComponentInput
        id="data-updating"
        type="checkbox"
        label="Актуализация данных по Сквозным задачам"
        mod="checkbox-green"
        v-model="dataUpdating"
      />
    </div>
    <div class="coll coll-6 coll-t-12 coll-m-12">
      <ComponentInput
        id="updating-key-results"
        type="checkbox"
        label="Актуализация ключевых результатов"
        mod="checkbox-green"
        v-model="updatingKeyResults"
      />
    </div>
    <div class="coll coll-6 coll-m-12">
      <dateComponent
        label="Срок <span>*</span>"
        v-model="date"
        modLabel="big"
        modPicker="rectangular"
        :clearable="false"
        :lowerLimit="lowerLimit"
        :dateIcon="true"
        modClass="modal-request"
        required
      />
    </div>
    <div class="coll coll-12" v-if="errorCreate">
      <div class="error-content">Произошла ошибка, пожалуйста, повторите попытку.</div>
    </div>
    <div class="form-create-request__button">
      <ButtonComponent
        @click.prevent.stop="onCreated"
        mod="gradient-bg"
        class="button-redesign--map-apply"
        :disabled="disabledButton"
        >Создать запрос</ButtonComponent
      >
      <ButtonComponent @click.prevent.stop="onClose" :disabled="disabledButton">Отмена</ButtonComponent>
    </div>
  </form>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import dateComponent from '@/common/components/dateComponent.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';

  export default {
    name: 'FormCreateRequest',
    components: {
      SelectMultiComponent,
      SelectMultiComponentCheckbox,
      ComponentInput,
      dateComponent,
      ButtonComponent,
    },
    props: {
      nameTask: {
        type: String,
        default: '',
      },
      optionEmployees: {
        type: Array,
        default: () => [],
      },
      optionCountry: {
        type: Array,
        default: () => [],
      },
      optionResponsibleFOIV: {
        type: Array,
        default: () => [],
      },
      errorCreate: {
        type: Boolean,
        default: false,
      },
      disabledButton: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        name: this.nameTask,
        countryId: null,
        responsibleFOIV: [],
        employees: [],
        textRequest: '',
        errorCountry: false,
        date: '',
        dataUpdating: true,
        updatingKeyResults: true,
        lowerLimit: new Date(),
        errorResponsibleFOIV: false,
      };
    },
    watch: {
      countryId() {
        this.errorCountry = false;
      },
      responsibleFOIV() {
        this.errorResponsibleFOIV = false;
      },
    },
    methods: {
      onCreated() {
        this.v$.$touch();

        if (!this.countryId) {
          this.errorCountry = true;
        }

        if (!this.responsibleFOIV.length) {
          this.errorResponsibleFOIV = true;
        }

        if (this.v$.$invalid) return;

        const dataForm = {
          crossCuttingtaskName: this.name,
          countries: [{ id: this.countryId }],
          isDataUpdating: this.dataUpdating,
          isKeyResultsUpdating: this.updatingKeyResults,
          deadline: this.date,
          text: this.textRequest,
        };
        if (this.responsibleFOIV && this.responsibleFOIV?.length) {
          dataForm.organizations = this.responsibleFOIV.map((i) => ({ id: i }));
        }
        if (this.employees && this.employees?.length) {
          dataForm.employees = this.employees.map((i) => ({ id: i }));
        } else {
          dataForm.employees = [];
        }
        const index = this.optionCountry.findIndex((i) => +i.value === this.countryId);
        if (index !== -1) {
          dataForm.countryName = this.optionCountry[index].label;
        }
        this.$emit('submit', dataForm);
      },
      onClose() {
        this.$emit('close');
      },

      // вызывается через ref
      /**@public */
      reset() {
        this.countryId = null;
        this.responsibleFOIV = [];
        this.employees = [];
        this.dataUpdating = true;
        this.updatingKeyResults = true;
        this.date = '';
        this.textRequest = '';
        this.errorCountry = false;
        this.v$.$reset();
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        countryId: { required },
        textRequest: { required },
        date: { required },
        responsibleFOIV: { required },
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '../styles/coll.scss';

  .form-create-request {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;

    &__title {
      font: $fira-20-M;
      margin: 0 8px 24px;
    }

    &__button {
      display: flex;
      width: calc(100% - 16px);
      margin: 24px 8px 0;
    }
  }
</style>
