import axios from 'axios';

import { authAxios } from '@/common/axios';

export default {
  // метод для Put запроса
  // преобразует данные для тела запроса
  convertPutMeth(params) {
    let convert = '';
    for (var key in params) {
      if (!convert) {
        convert += `${key}=${params[key]}`;
      } else {
        convert += `&${key}=${params[key]}`;
      }
    }
    return convert;
  },

  // страница Аналитика по сквозным задачам
  // получение списка
  getPageCrossCuttingTask() {
    return axios.get('/api/crosscuttingtask/analyticsfortasks');
  },

  // детальная страница Аналитика по сквозным задачам
  getDetailPageCrossCuttingTask(params) {
    return axios.get(`/api/crosscuttingtask/taskforallcountries`, { params });
  },

  // актуальна ли карточка
  putEditActualCard(params) {
    const data = this.convertPutMeth(params);
    return axios.put(`/api/crosscuttingtask/editisactualcard?${data}`);
  },

  // обновление стран
  putUpdateCountries(params) {
    const { arrayParams, taskId } = params;
    return axios.put(`/api/crosscuttingtask/updatecountries?taskId=${taskId}${arrayParams}`);
  },

  // редактирование ключевых результатов
  putEditKeyResults(params) {
    const data = this.convertPutMeth(params);
    return axios.put(`/api/crosscuttingtask/editkeyresults?${data}`);
  },

  // получение списка ФОИВ сотрудников
  getListFOIV(params) {
    return authAxios.get('api/organization/searchShortFormat', { params });
  },

  // получение списка сотрудников
  getListEmployees() {
    return axios.get('api/task/getEmployeeList');
  },

  // получение списка стран по задаче
  getListCountries(params) {
    return axios.get('/api/crosscutingtaskrequest/countriesfromtask', { params });
  },

  // создание Запроса
  postCreateRequest(params) {
    return axios.post('/api/crosscutingtaskrequest/create', params);
  },
};
