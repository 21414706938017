<template>
  <div class="card-request">
    <div class="card-request__name">{{ getName }}</div>
    <div class="card-request__country">Страны: {{ getCountry }}</div>
    <div class="card-request__text-title">Текст запроса</div>
    <div class="card-request__text">{{ getText }}</div>
    <div class="card-request__date">Срок выполнения: {{ getDate }}</div>
  </div>
</template>

<script>
  export default {
    name: 'CardRequest',
    props: {
      info: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      getName() {
        return this.info?.crossCuttingtaskName || '';
      },
      getCountry() {
        return this.info?.countryName || '';
      },
      getText() {
        return this.info?.text || '';
      },
      getDate() {
        if (this.info?.deadline) {
          console.log('this.info.deadline', this.info.deadline);
          const [y, m, d] = String(this.info.deadline).split('-');
          return `${d}.${m}.${y} г.`;
        }
        return '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-request {
    &__name {
      font: $fira-20-M;
      margin-bottom: 8px;
    }

    &__country {
      font: $fira-16;
      margin-bottom: 24px;
    }

    &__text {
      border-radius: 4px;
      border: 1px solid $grey;
      background: $light-grey;
      min-height: 112px;
      font: $fira-16;
      margin-bottom: 16px;
      padding: 8px 16px;

      &-title {
        font: $fira-16;
        margin-bottom: 8px;
      }
    }

    &__date {
      font: $fira-16;
    }

    @media (max-width: $laptop) {
      &__text {
        margin-bottom: 8px;
      }
    }
  }
</style>
